<template>
  <v-container class="d-flex flex-column justify-start" fluid>
    <v-row>
      <v-col class="px-0">
        <RunEditor
          v-if="selectedItem"
          :item="selectedItem"
          @update-filter="updateItem"
        />
        <CaseView
          :caseType = "'EditCase'"
          :form = "selectedItem"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RunEditor from '@/components/TestRuns/RunEditor.vue';
import CaseView from '@/views/Tests/Runs/Create/RunAddCaseView';
import { mapGetters, mapState ,createNamespacedHelpers} from 'vuex';
const { mapState: mapProjectState, mapMutations, mapActions } = createNamespacedHelpers('run');
import { showSuccessToast,showAlertToast, showErrorToast } from '@/utils/toast';

export default {
  name: 'TestRunEdit',
  props: ['customItem'],
  components: {
    RunEditor,
    CaseView,
  },
  data() {
    return {
      selectedItem: null,
    };
  },
  computed: {
    ...mapState('run', ['items']),
    ...mapGetters({
      currentOrg: 'user/currentAccount',
    }),
  },
  async created() {
    const handle = this.$route.params.handle;
    const projectKey = this.$route.params.key;
    const runId = this.$route.params.id;
    await this.getProjectRun(     
      handle,
      projectKey,
      runId
    );
  },
  methods: {
    ...mapActions(['getTestRunById']),
    updateItem(item){
      this.selectedItem = item
    },
    async getProjectRun(handle, projectKey, runId) {
      await this.getTestRunById({ handle, projectKey ,runId}).then( response => {
          this.selectedItem = response.data[0]
        }).catch(() => {
          showErrorToast(this.$swal, this.$t('testruns.edit_testrun.not_found'))
          this.$router.push({name: 'Runs'})
        })
      
    },
    // Refreshes all data
    async refreshData() {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;
      const runId = this.$route.params.id;
      this.getProjectRun(
        handle,
        projectKey,
        runId
      );
    },
  }
};
</script>